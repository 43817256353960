<script setup>
import { usePage, router } from "@inertiajs/vue3";
import { computed, onMounted, watch } from "vue";
import { useLocale } from "vuetify";

const page = usePage();

// Start locale processing
const { current } = useLocale();
const user = computed(() => page.props.auth.user);
const userServerLocale = computed(() => user.value?.locale);

function setLocale(lang) {
    if (lang) {
        current.value = lang;
        localStorage.setItem("lang", lang);
    } else if (localStorage.getItem("lang")) {
        current.value = localStorage.getItem("lang");
    }
}

router.on("finish", (event) => {
    setLocale(userServerLocale.value);
});

onMounted(() => {
    setLocale(userServerLocale.value);
});

watch(userServerLocale, (newValue) => {
    setLocale(userServerLocale.value);
});
// End locale processing
</script>

<template></template>

<style scoped></style>
